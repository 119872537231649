import { useAuth0 } from '@auth0/auth0-react'
import useSWR from 'swr'
import { Auth0Client } from '@auth0/auth0-spa-js'

/**
 * @deprecated use getAccessToken instead
 * @param withIdToken
 * @returns
 */
export default function useAccessToken(withIdToken = false) {
  const { getAccessTokenSilently, getIdTokenClaims, isAuthenticated, isLoading, loginWithRedirect } = useAuth0()
  const tokenFetcher = async (withIdToken: boolean, isAuthenticated: boolean) => {
    const ret = {
      accessToken: undefined as string | undefined,
      idToken: undefined as string | undefined
    }
    if (isAuthenticated) {
      ret.accessToken = await getAccessTokenSilently()
      if (withIdToken) {
        const idTokenClaims = await getIdTokenClaims()
        ret.idToken = idTokenClaims?.__raw
      }
    } else {
      ret.accessToken = 'anonymous'
      ret.idToken = 'anonymous'
    }
    return ret
  }

  const { data, error } = useSWR(
    !isLoading ? ['accessToken', withIdToken, isAuthenticated] : null,
    () => tokenFetcher(withIdToken, isAuthenticated),
    {
      refreshInterval: 1000 * 60, // 1 hour
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        if (error.status === 401) {
          loginWithRedirect()
        } else {
          revalidate({ retryCount: retryCount + 1 })
        }
      }
    }
  )

  return {
    accessToken: data?.accessToken,
    idToken: data?.idToken
  }
}

export const getAccessToken = async (useIdToken = false) => {
  const auth0Client = (window as any).auth0Client as Auth0Client
  if (auth0Client) {
    try {
      const accessToken = await auth0Client.getTokenSilently({
        cacheMode: useIdToken ? 'off' : 'on'
      })
      if (useIdToken) {
        const idTokenClaims = await auth0Client.getIdTokenClaims()
        return idTokenClaims?.__raw
      }
      return accessToken
    } catch (e: any) {
      if (e.status === 401) {
        await auth0Client.loginWithRedirect()
      } else {
        return 'anonymous'
      }
    }
  }
}
