import { useEffect, useState } from 'react'
import { BaseErrorPageLayout, BaseErrorContent } from './BaseErrorPage'
import { useAuth0 } from '@auth0/auth0-react'

export default function NoAccessPermission() {
  const [path, setPath] = useState('')
  const { isAuthenticated, loginWithRedirect } = useAuth0()
  useEffect(() => {
    setPath(document.location.pathname)
  }, [])
  const [owner, slug, page, id] = path.slice(1).split('/')
  return (
    <BaseErrorPageLayout code={403}>
      <BaseErrorContent
        title={'No Access Permission'}
        description={
          isAuthenticated
            ? "You don't have permission to access this page. Please login with the correct account or contact the project owner."
            : 'Anonymously access is not allowed in this page. Please login or register to access this page.'
        }
        buttons={
          isAuthenticated
            ? [
                {
                  text: `Go to ${owner}/${slug}`,
                  type: 'primary',
                  href: `/${owner}/${slug}`
                },
                {
                  text: 'Go back home',
                  type: 'secondary',
                  href: '/'
                },
                {
                  text: 'Sentio Documentation',
                  type: 'secondary',
                  href: 'https://docs.sentio.xyz'
                }
              ]
            : [
                {
                  text: `Go to ${owner}/${slug}`,
                  type: 'secondary',
                  href: `/${owner}/${slug}`
                },
                {
                  text: `Login / Register`,
                  type: 'primary',
                  href: `/login?redirect=${path}`,
                  onClick: () => {
                    loginWithRedirect({
                      appState: { returnTo: path }
                    })
                  }
                },
                {
                  text: 'Go back home',
                  type: 'secondary',
                  href: '/'
                },
                {
                  text: 'Sentio Documentation',
                  type: 'secondary',
                  href: 'https://docs.sentio.xyz'
                }
              ]
        }
      />
    </BaseErrorPageLayout>
  )
}
