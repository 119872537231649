// import { datadogRum } from '@datadog/browser-rum'

import TagManager from 'react-gtm-module'

import mixpanel from 'mixpanel-browser'

export function SetupMonitoring() {
  const hostname = typeof window !== 'undefined' && window.location.hostname
  const search = typeof window !== 'undefined' ? new URLSearchParams(window.location.search) : undefined

  let env: undefined | string
  let sampleRate = 0
  let premiumSampleRate = 0
  let trackInteractions = false
  let gtmId: undefined | string
  let mixpanelToken: undefined | string

  switch (hostname) {
    case 'test.sentio.xyz':
      env = 'test'
      sampleRate = 100
      premiumSampleRate = 100
      trackInteractions = true
      gtmId = 'G-493CEEC9LP'
      mixpanelToken = '8ef3bd91cdefef79e6063dcd80cb369c'
      break
    case 'staging.sentio.xyz':
      env = 'staging'
      sampleRate = 100
      premiumSampleRate = 0
      trackInteractions = false
      gtmId = 'G-2THX5XTL0G'
      break
    case 'sentio.xyz':
    case 'app.sentio.xyz':
      env = 'prod'
      sampleRate = 100
      premiumSampleRate = 10
      trackInteractions = true
      gtmId = 'G-ZEVK4WH0DT'
      mixpanelToken = 'fb250c0e249067bccdf8befc84afab27'
      break
    case 'dash.sentio.xyz':
      env = 'prod'
      sampleRate = 100
      premiumSampleRate = 10
      trackInteractions = true
      gtmId = 'G-ZEVK4WH0DT' // TODO: use a different GTM ID
      mixpanelToken = '6bf1461f972ee49fdab5b675ff0c4bbd'
      break
    // case 'sui.localhost':
    case 'sui-test.sentio.xyz':
      env = 'test'
      sampleRate = 100
      premiumSampleRate = 100
      trackInteractions = true
      gtmId = 'G-493CEEC9LP' // TODO: use a different GTM ID
      mixpanelToken = '95efdefff5c8016abc88f881b15a6e00'
      break
    default:
      break
    // env = 'test' // local test
    // sampleRate = 100
    // trackInteractions = true
  }

  if (env) {
    if (search && search.has('dd-trace')) {
      sampleRate = 100
      premiumSampleRate = 100
    }
    // datadogRum.init({
    //   applicationId: 'f157e7fb-4b6b-452d-a39a-8ed42b5e8caf',
    //   clientToken: 'pub8e911bfffb6d4f341698c7a21236b736',
    //   site: 'datadoghq.com',
    //   service: 'app',
    //   env: env,
    //   // TODO Specify a version number to identify the deployed version of your application in Datadog
    //   // version: '1.0.0',
    //   traceSampleRate: sampleRate,
    //   sessionSampleRate: sampleRate,
    //   sessionReplaySampleRate: premiumSampleRate,
    //   trackUserInteractions: trackInteractions,
    //   defaultPrivacyLevel: 'mask-user-input',

    //   trackResources: true,
    //   trackLongTasks: true

    //   // allowedTracingOrigins: [window.location.origin],
    // })
  }
  // console.log('Datadog Rum started')

  mixpanel.init(mixpanelToken || 'XXX', { debug: env === 'test', track_pageview: 'url-with-path', persistence: 'localStorage' } as any)
  if (!mixpanelToken) {
    mixpanel.opt_out_tracking()
  }

  if (gtmId) {
    TagManager.initialize({ gtmId })
  }
}

SetupMonitoring()
