import {} from 'react'
import { useUpdateOwnerAndSlug, useUpdateProjectData } from 'lib/data/use-project-context'
import { projectOwner, projectSlug, projectData } from 'lib/data/atoms'
import { useAtomValue } from 'jotai'

/**
 * fake component to initialize owner, slug, project data
 * @returns null
 */
export const InitProject = () => {
  useUpdateOwnerAndSlug()
  useUpdateProjectData()
  return null
}

export const ShowProject = () => {
  const owner = useAtomValue(projectOwner)
  const slug = useAtomValue(projectSlug)
  const project = useAtomValue(projectData)

  return (
    <div>
      <h1>
        Project: {owner}/{slug}
      </h1>
      <pre>{JSON.stringify(project, null, 2)}</pre>
    </div>
  )
}
