import { atom } from 'jotai'
import { Project } from 'gen/service/common'

/**
 * project atoms
 */
export const projectOwner = atom<string | null>(null)
export const projectSlug = atom<string | null>(null)
export const projectData = atom<Project | null>(null)

// derived atoms
export const isProjectFreeTier = atom((get) => {
  const project = get(projectData)
  return project?.owner?.tier === 'FREE'
})

/**
 * dashboard atoms
 */
export const newDashboardDialogOpen = atom(false)
