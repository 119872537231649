import * as React from 'react'
import { ArrowLeftIcon } from '@heroicons/react/20/solid'
import classNames from 'lib/classnames'
import Link from 'next/link'
import { MobileMenuContext } from 'components/layout/MobileMenu'

interface Props {
  title: string | React.ReactNode
  subtitle?: string
  children?: React.ReactNode
  back?: string
  buttons?: React.ReactNode
  leftButton?: React.ReactNode
  className?: string
}

export default function PageHeader({ title, subtitle, children, back, buttons, leftButton, className }: Props) {
  const mobileMenu = React.useContext(MobileMenuContext)
  return (
    <div
      className={classNames(
        className,
        'border-border-color dark:bg-sentio-gray-100 flex flex-wrap items-center justify-between gap-1 border-b bg-white py-2'
      )}
      data-testid="page-header"
    >
      <div className="flex-0 inline-flex items-start self-start pl-2 sm:hidden">{mobileMenu}</div>
      <div className="flex flex-1 items-start">
        <div className="flex flex-1 flex-wrap items-center gap-y-1">
          {back && (
            <Link
              href={back}
              type="button"
              className="hover:text-primary hover:bg-primary-100 focus:ring-primary-500 text-text-foreground ml-2 inline-flex items-center rounded-md border border-transparent px-2 py-1 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2"
            >
              <ArrowLeftIcon className="h-4 w-4" aria-hidden="true" />
            </Link>
          )}
          {leftButton}
          {typeof title === 'string' ? (
            <h1 className="text-ititle text-text-foreground pl-4 font-bold">{title}</h1>
          ) : (
            title
          )}
          <p className="text-icontent font-icontent mx-2 truncate text-gray-500 sm:mt-1">{subtitle}</p>
          {buttons}
        </div>
        <div className="flex-0 inline-flex justify-end pr-4 sm:hidden">
          <img className="mt-1 h-5 w-auto" src={'/logo-mini.png'} alt="Sentio" />
        </div>
      </div>
      {children ? (
        <div className="border-border-color flex w-full flex-wrap gap-2 border-t pl-4 pt-2 sm:-my-px sm:w-fit sm:border-0 sm:pt-0 lg:pr-4">
          {children}
        </div>
      ) : null}
    </div>
  )
}
