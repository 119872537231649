import { useAuth0 } from '@auth0/auth0-react'

const LogoutButton = ({ className }: { className?: string }) => {
  const { logout } = useAuth0()
  return (
    <a
      onClick={(e) => {
        e.preventDefault()
        logout()
      }}
      href="/logout"
      type="button"
      className={
        className ||
        'ml-3 inline-flex items-center border border-gray-300 px-3 py-2  text-sm font-medium leading-4 shadow-sm ' +
          'focus:ring-primary-500 dark:bg-sentio-gray-100 rounded-md bg-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2'
      }
    >
      Logout
    </a>
  )
}

export default LogoutButton
