import { useEffect, useState } from 'react'

export function getStorageValue<T>(key): T | undefined {
  if (typeof window !== 'undefined') {
    const saved = localStorage.getItem(key)
    if (saved != null) {
      return JSON.parse(saved)
    }
  }
}

export function useLocalStorage<T>(
  key: string | undefined | null,
  defaultValue: T,
  clearWhenOutOfQuota = false
): [T, (value?: T) => void] {
  const [value, setValue] = useState<T>(() => {
    return (key && getStorageValue(key)) || defaultValue
  })

  const setStoredValue = (newValue?: T) => {
    if (key) {
      if (newValue == null) {
        localStorage.removeItem(key)
        window.dispatchEvent(new StorageEvent('local-storage', { key, newValue: undefined }))
      } else {
        try {
          localStorage.setItem(key, JSON.stringify(newValue))
          window.dispatchEvent(new StorageEvent('local-storage', { key, newValue: JSON.stringify(newValue) }))
        } catch (e) {
          if (e instanceof DOMException && e.name === 'QuotaExceededError') {
            console.error('localStorage quota exceeded')
            if (clearWhenOutOfQuota) {
              localStorage.removeItem(key)
            }
          }
        }
      }
    }
  }

  function onStorageChanged(event: any) {
    if (event.key === key) {
      const newValue = getStorageValue<T>(key) || defaultValue
      setValue(newValue)
    }
  }

  useEffect(() => {
    if (key) {
      window.addEventListener('local-storage', onStorageChanged)
      window.addEventListener('storage', onStorageChanged)
      return () => {
        window.removeEventListener('local-storage', onStorageChanged)
        window.removeEventListener('storage', onStorageChanged)
      }
    }
  }, [key])

  return [value, setStoredValue]
}
