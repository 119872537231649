import { createContext, ReactNode, useState, useMemo } from 'react'

export interface NotificationProps {
  title: string
  message: string
  type: 'success' | 'error'
  buttons?: () => ReactNode
}

export interface INotificationContext {
  props: NotificationProps
  showNotification: (props: NotificationProps, closeDelay: number) => void
}

export const NotificationContext = createContext<INotificationContext>(null as unknown as INotificationContext)

export function useNotification() {
  const [show, setShow] = useState(false)
  const [props, setProps] = useState<NotificationProps>({
    title: '',
    message: '',
    type: 'success',
  })

  const showNotification = (props: NotificationProps, closeDelaySeconds = 3) => {
    setProps(props)
    setShow(true)
    if (closeDelaySeconds > 0) {
      setTimeout(() => {
        setShow(false)
      }, closeDelaySeconds * 1000)
    }
  }

  return useMemo(
    () => ({
      showNotification,
      show,
      props,
      setShow,
    }),
    [show, props]
  )
}
