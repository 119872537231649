import '../styles/globals.css'
import '../styles/graphql.css'
import type { AppProps } from 'next/app'
import { ReactElement, ReactNode, useEffect } from 'react'
import type { NextPage } from 'next'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import { SWRConfig } from 'swr'
import { NotificationContext, useNotification } from 'lib/data/use-notification'
import Notification from 'components/common/notification/Notification'
import { Auth0Provider } from '@auth0/auth0-react'
import Router, { useRouter } from 'next/router'
import LogoutButton from 'components/common/buttons/LogoutButton'
import { ErrorBoundary } from 'react-error-boundary'
import 'lib/monitoring'
import NotFound from 'components/error/NotFound'
import NoPermission from 'components/error/NoPermission'
import ErrorPage from 'components/error/ErrorPage'
import 'prismjs'
// Import other Prism themes here
import 'prismjs/components/prism-bash.min'
import 'prismjs/themes/prism.css'
import { Provider } from 'jotai'
import { InitProject } from 'components/common/util/InitProject'
import { Auth0Client } from '@auth0/auth0-spa-js'
import { loader } from '@monaco-editor/react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

// use latest monaco editor
loader.config({
  paths: {
    vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.47.0/min/vs'
  }
})

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const permissions = ['openid', 'offline_access', 'profile', 'email']

const onRedirectCallback = (appState) => {
  // Use Next.js's Router.replace method to replace the url
  Router.replace(appState?.returnTo || '/')
}

const fallback = ({ error, resetErrorBoundary }) => {
  const e = error as any
  if (e.status == 404 || e.status == 401) {
    return <NotFound />
  } else if (e.status == 403) {
    return <NoPermission />
  }
  return <ErrorPage error={error?.message} resetError={resetErrorBoundary} />
}

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout || ((page) => page)
  const notification = useNotification()
  const hostname = process.env['NEXT_PUBLIC_HOST'] || (typeof window !== 'undefined' && window.location.hostname)
  const router = useRouter()
  let domain, clientId, audience: string
  switch (hostname) {
    case 'localhost':
      domain = 'https://sentio-dev.us.auth0.com'
      clientId = 'JREam3EysMTM49eFbAjNK02OCykpmda3'
      audience = 'http://localhost:8080/v1'
      break
    case 'sentio.xyz':
    case 'dash.sentio.xyz':
    case 'app.sentio.xyz':
      domain = 'https://auth.sentio.xyz'
      clientId = '66oqMrep54LVI9ckH97cw8C4GBA1cpKW'
      audience = 'https://app.sentio.xyz/api/v1'
      break
    case 'staging.sentio.xyz':
    case 'sui-test.sentio.xyz':
    case 'test.sentio.xyz':
      domain = 'https://auth.test.sentio.xyz'
      clientId = '6SH2S1qJ2yYqyYGCQOcEnGsYgoyONTxM'
      audience = 'https://test.sentio.xyz/api/v1'
      break
    default:
      if (hostname && hostname.startsWith('dev-')) {
        // Same as test & staging
        domain = 'https://auth.test.sentio.xyz'
        clientId = '6SH2S1qJ2yYqyYGCQOcEnGsYgoyONTxM'
        audience = 'https://test.sentio.xyz/api/v1'
      } else {
        // console.error('Unsupported Host')
        audience = ''
      }
      break
  }

  const isDev = ['localhost', 'dev-', 'test.'].some((h) => (hostname || '').startsWith(h))

  const onError = (error, key) => {
    if (error.status < 500) {
      if (error.status == 403) {
        const title = 'Occurred a permission error'
        const message = error.body?.message || "You don't have permission to access this page"
        if (router.pathname.startsWith('/share/')) {
          notification.showNotification({ title, message, type: 'error' }, 10)
        } else {
          notification.showNotification({ title, message, type: 'error', buttons: () => <LogoutButton /> }, 10)
        }
      } else if (error.status == 404 || error.status == 401 || error.status == 429) {
        // ignore 404
      } else {
        // other case such as invalid argument, should be handled by specific component
      }
    } else {
      console.log('error', error)
      notification.showNotification(
        {
          title: 'Occurred an error',
          message: isDev ? error.body?.message : 'Server error',
          type: 'error'
        },
        10
      )
    }
  }

  useEffect(() => {
    ;(window as any).auth0Client = new Auth0Client({
      domain: domain,
      clientId: clientId,
      authorizationParams: {
        audience,
        scope: permissions.join(' '),
        redirect_uri: typeof window !== 'undefined' ? window.location.origin : ''
      },
      cacheLocation: 'localstorage',
      useRefreshTokens: true,
      useRefreshTokensFallback: true
    })
  }, [audience, clientId, domain])

  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }
    //reset theme
    document.body.classList.remove('light', 'dark')

    //disable dark mode for specific domains
    if (['dash.sentio.xyz', 'sui-test.sentio.xyz'].includes(window.location.host)) {
      document.body.classList.add('light')
      return
    }

    //listen to theme change
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')

    // Define a function to update the theme
    const updateTheme = (matches: boolean) => {
      if (localStorage.getItem('theme') !== 'system') {
        return
      }
      document.body.classList.remove('light', 'dark')
      document.body.classList.add(matches ? 'dark' : 'light')
    }

    // Add event listener for media query changes
    const handler = (event: MediaQueryListEvent) => updateTheme(event.matches)
    mediaQuery.addEventListener('change', handler)

    let currentTheme = 'light'
    const selectedTheme = localStorage.getItem('theme')

    if (selectedTheme === 'dark') {
      currentTheme = 'dark'
    } else if (selectedTheme === 'system') {
      currentTheme = mediaQuery.matches ? 'dark' : 'light'
    }
    document.body.classList.add(currentTheme)

    // Cleanup listener on component unmount
    return () => {
      mediaQuery.removeEventListener('change', handler)
    }
  }, [])

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience,
        scope: permissions.join(' '),
        redirect_uri: typeof window !== 'undefined' ? window.location.origin : ''
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation={'localstorage'}
      useRefreshTokens={true}
      useRefreshTokensFallback={false}
    >
      <SWRConfig value={{ onError: onError }}>
        <ErrorBoundary fallbackRender={fallback}>
          <Provider>
            <DndProvider backend={HTML5Backend}>
              <NotificationContext.Provider value={notification}>
                <InitProject />
                {getLayout(<Component {...pageProps} />)}
                <Notification show={notification.show} setShow={notification.setShow} {...notification.props} />
              </NotificationContext.Provider>
            </DndProvider>
          </Provider>
        </ErrorBoundary>
      </SWRConfig>
    </Auth0Provider>
  )
}

export default MyApp
