import { atom, useAtomValue, useSetAtom } from 'jotai'
import { WebService } from 'gen/service/web'
import useApi from './use-api'
import { useEffect } from 'react'
import { useRouter } from 'next/router'

import { projectOwner, projectSlug, projectData } from './atoms'

const projectReq = atom((get) => {
  const owner = get(projectOwner)
  const slug = get(projectSlug)
  if (owner && slug) {
    return {
      ownerName: owner,
      slug: slug
    }
  }
})

export const useUpdateProjectData = () => {
  const req = useAtomValue(projectReq)
  const setProject = useSetAtom(projectData)

  // for dash project, no need wait for auth0 because dash project is public
  // const { isLoading: isAuth0Loading } = useAuth0()
  const { data, error } = useApi(WebService.GetProject, req?.slug && req?.slug ? req : null)

  useEffect(() => {
    if (data?.project) {
      setProject(data.project)
    }
  }, [data, setProject])
}

export const useUpdateOwnerAndSlug = () => {
  const setOwner = useSetAtom(projectOwner)
  const setSlug = useSetAtom(projectSlug)
  const router = useRouter()
  const { owner, slug } = router.query

  useEffect(() => {
    setOwner(owner as string)
    setSlug(slug as string)
  }, [owner, slug, setOwner, setSlug])
}
