import Head from 'next/head'
import { BaseErrorPageLayout, BaseErrorContent, ButtonType } from './BaseErrorPage'

interface Props {
  error: string
  resetError?: () => void
  title?: string
}

export default function Error({ error, resetError, title }: Props) {
  const buttons: ButtonType[] = [
    {
      text: 'Go back home',
      type: 'primary',
      href: '/',
    },
  ]
  if (resetError) {
    buttons.push({
      text: 'Try reset',
      type: 'secondary',
      href: '#',
      onClick: resetError,
    })
  }
  return (
    <BaseErrorPageLayout code={500}>
      <BaseErrorContent title={title} description={error} buttons={buttons} />
    </BaseErrorPageLayout>
  )
}

Error.defaultProps = {
  title: 'Unexpect things happened.',
}
