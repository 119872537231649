import Head from 'next/head'
import PageHeader from 'components/common/header/PageHeader'
import Image from 'next/image'
import { useDarkMode } from 'lib/util/use-dark-mode'

export type ButtonType = {
  text: string
  type: 'primary' | 'secondary'
  href: string
  onClick?: () => void
}

const classNames = {
  primary:
    'inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-500 active:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
  secondary:
    'inline-flex items-center px-4 py-2 border border-primary-600 hover:border-primary-500 active:border-primary-800 text-sm font-medium rounded-md shadow-sm text-primary-700 hover:text-primary-500 active:text-primary-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'
}

export const BaseErrorContent = ({
  title,
  description,
  buttons
}: {
  title?: React.ReactNode
  description?: React.ReactNode
  buttons?: ButtonType[]
}) => {
  return (
    <main className="sm:flex">
      <div className="">
        <div className="">
          <h1 className="text-text-foreground text-center text-2xl font-extrabold tracking-tight">{title}</h1>
          <p className="mt-3 text-center text-base text-gray-500">{description}</p>
        </div>
        <div className="mt-6 flex flex-wrap justify-center gap-3 sm:border-l sm:border-transparent sm:pl-6">
          {buttons?.map((button, index) => (
            <a
              key={`${button.href}_${index}`}
              href={button.href}
              className={classNames[button.type]}
              onClick={button.onClick}
            >
              {button.text}
            </a>
          ))}
        </div>
      </div>
    </main>
  )
}

export const BaseErrorPageLayout = ({ code, children }: { code: number | string; children: React.ReactNode }) => {
  const isDarkMode = useDarkMode()
  return (
    <>
      <Head>
        <title>{code}</title>
      </Head>
      <div className="flex h-screen flex-col">
        <PageHeader
          title={
            <Image
              src={isDarkMode ? '/logo-dark.png' : '/logo.png'}
              alt="sentio logo"
              width={96}
              height={28}
              className="ml-4"
            />
          }
        />
        <div className="flex-1 px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div
            className="relative mx-auto grid min-h-[400px] max-w-max -translate-y-12 place-items-center bg-contain bg-center bg-no-repeat"
            style={{
              backgroundImage: 'url("/light-gray-logo.png")'
            }}
          >
            <div className="space-y-3">
              <div className="text-center text-5xl font-semibold text-gray-800">{code}</div>
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
