import { useEffect, useState } from 'react'
import useSWR, { SWRConfiguration, useSWRConfig } from 'swr'
import { getAccessToken } from './use-access-token'
import { useLocalStorage } from './use-local-storage'

const initReq = { pathPrefix: '' }

export interface UseApiOptions extends SWRConfiguration {
  fromShare?: { shareId?: string; panelId?: string }
  fromExternalProject?: { projectId?: string; externalProjectId?: string }
}

type WebApi<R> = (A, any) => Promise<R>
export default function useApi<A, R>(f: WebApi<R>, req: A | null, useIdToken = false, options?: UseApiOptions) {
  const [token, setToken] = useState('')
  const isReady = !!(token && req != null)
  const [adminMode] = useLocalStorage('sentio_admin_mode', false)
  useEffect(() => {
    getAccessToken().then((token) => setToken(token ?? 'anonymous'))
  }, [useIdToken])

  const { data, error, mutate } = useSWR(
    isReady ? [getKey(f), req] : null,
    async () => {
      const token = await getAccessToken(useIdToken)
      const headers = {
        'content-type': 'application/json',
        authorization: token == null || token == 'anonymous' ? undefined : `Bearer ${token}`
      }
      if (options?.fromShare?.shareId) {
        let share = `${options.fromShare.shareId}`
        if (options.fromShare.panelId) {
          share += `/${options.fromShare.panelId}`
        }
        headers['share-dashboard'] = share
      }

      if (options?.fromExternalProject?.externalProjectId) {
        const { projectId, externalProjectId } = options.fromExternalProject
        headers['external-project'] = `${projectId}/${externalProjectId}`
      }

      if (token != 'anonymous' && adminMode) {
        headers['x-admin-mode'] = 'true'
      }

      return f(req, {
        initReq,
        headers
      })
    },
    options
  )

  return {
    data: data as R,
    error: error,
    loading: error?.status == 429 || (!data && !error), // treat 429 as loading because it will retry later
    mutate,
    token,
    ready: isReady,
    fetching: isReady ? !data && !error : false // request ready and wait for data or error
  }
}

export function getKey(f: WebApi<any>) {
  const regex = /\("\/api\/.+\?"/m
  const match = f.toString().match(regex)
  return match ? match[0] : f.toString()
}

export function useApiMutate() {
  const { cache, mutate } = useSWRConfig()
  return (f: WebApi<any>, ...args) => {
    if (!(cache instanceof Map)) {
      throw new Error('matchMutate requires the cache provider to be a Map instance')
    }

    const keys: string[] = []
    const funcKey = getKey(f)
    for (const key of Array.from(cache.keys())) {
      if ((key as string).indexOf(funcKey) >= 0) {
        keys.push(key)
      }
    }

    const mutations = keys.map((key) => mutate(key, ...args))
    return Promise.all(mutations)
  }
}

export function useApiWithoutToken<A, R>(f: WebApi<R>, req: A | null, options?: SWRConfiguration) {
  const isReady = !!(req != null)

  const { data, error, mutate } = useSWR(
    isReady ? [getKey(f), req] : null,
    async () => {
      return f(req, {
        initReq,
        headers: {
          'content-type': 'application/json'
        }
      })
    },
    options
  )

  return {
    data: data as R,
    error,
    loading: !data && !error,
    mutate,
    ready: isReady
  }
}
