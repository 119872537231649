/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as fm from "../../../fetch.pb"
import * as GoogleProtobufEmpty from "../../../google/protobuf/empty.pb"
import * as GoogleProtobufStruct from "../../../google/protobuf/struct.pb"
import * as GoogleProtobufTimestamp from "../../../google/protobuf/timestamp.pb"
import * as CommonCommon from "../../common/protos/common.pb"
import * as Insights_serviceInsights_service from "../../insights/protos/insights_service.pb"

type Absent<T, K extends keyof T> = { [k in Exclude<keyof T, K>]?: undefined };
type OneOf<T> =
  | { [k in keyof T]?: undefined }
  | (
    keyof T extends infer K ?
      (K extends string & keyof T ? { [k in K]: T[K] } & Absent<T, K>
        : never)
    : never);

export enum DashboardDashboardVisibility {
  INTERNAL = "INTERNAL",
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
}

export enum NoteFontSize {
  MD = "MD",
  SM = "SM",
  LG = "LG",
  XL = "XL",
  XXL = "XXL",
}

export enum NoteAlignment {
  LEFT = "LEFT",
  CENTER = "CENTER",
  RIGHT = "RIGHT",
}

export enum NoteVerticalAlignment {
  TOP = "TOP",
  MIDDLE = "MIDDLE",
  BOTTOM = "BOTTOM",
}

export enum ChartChartType {
  LINE = "LINE",
  AREA = "AREA",
  BAR = "BAR",
  BAR_GAUGE = "BAR_GAUGE",
  TABLE = "TABLE",
  QUERY_VALUE = "QUERY_VALUE",
  PIE = "PIE",
  NOTE = "NOTE",
}

export enum ChartDataSourceType {
  METRICS = "METRICS",
  NOTES = "NOTES",
  ANALYTICS = "ANALYTICS",
  INSIGHTS = "INSIGHTS",
  EVENTS = "EVENTS",
  RETENTION = "RETENTION",
  SQL = "SQL",
}

export enum ChartConfigDirection {
  HORIZONTAL = "HORIZONTAL",
  VERTICAL = "VERTICAL",
}

export enum ChartConfigCalculation {
  LAST = "LAST",
  FIRST = "FIRST",
  MEAN = "MEAN",
  TOTAL = "TOTAL",
  ALL = "ALL",
  MIN = "MIN",
  MAX = "MAX",
}

export enum ChartConfigSortBy {
  ByName = "ByName",
  ByValue = "ByValue",
}

export enum ChartConfigValueFormatter {
  NumberFormatter = "NumberFormatter",
  DateFormatter = "DateFormatter",
  StringFormatter = "StringFormatter",
}

export enum ChartConfigMarkerType {
  LINE = "LINE",
  AREA = "AREA",
  LINEX = "LINEX",
}

export enum ChartConfigValueConfigStyle {
  Standard = "Standard",
  Compact = "Compact",
  Scientific = "Scientific",
  Percent = "Percent",
  Currency = "Currency",
  None = "None",
}

export enum ChartConfigPieConfigPieType {
  Pie = "Pie",
  Donut = "Donut",
}

export enum ChartConfigLineConfigStyle {
  Solid = "Solid",
  Dotted = "Dotted",
}

export enum ListExternalDashboardsRequestOrderBy {
  STARRED = "STARRED",
  UPDATED_AT = "UPDATED_AT",
}

export type DashboardLayoutsLayout = {
  i?: string
  x?: number
  y?: number
  w?: number
  h?: number
}

export type DashboardLayouts = {
  layouts?: DashboardLayoutsLayout[]
}

export type DashboardResponsiveLayouts = {
  responsiveLayouts?: {[key: string]: DashboardLayouts}
}

export type DashboardExtraTemplateVariable = {
  field?: string
  defaultValue?: string
  sourceName?: string
  options?: string[]
}

export type DashboardExtraTemplateView = {
  values?: {[key: string]: string}
}

export type DashboardExtra = {
  templateVariables?: {[key: string]: DashboardExtraTemplateVariable}
  templateViews?: DashboardExtraTemplateView[]
}

export type Dashboard = {
  id?: string
  name?: string
  projectId?: string
  description?: string
  createdAt?: GoogleProtobufTimestamp.Timestamp
  updatedAt?: GoogleProtobufTimestamp.Timestamp
  panels?: {[key: string]: Panel}
  layouts?: DashboardResponsiveLayouts
  extra?: DashboardExtra
  sharing?: DashboardSharing
  default?: boolean
  visibility?: DashboardDashboardVisibility
  ownerId?: string
  tags?: string[]
  url?: string
}

export type Panel = {
  id?: string
  name?: string
  dashboardId?: string
  chart?: Chart
}

export type Note = {
  content?: string
  fontSize?: NoteFontSize
  textAlign?: NoteAlignment
  verticalAlign?: NoteVerticalAlignment
  backgroundColor?: string
  textColor?: string
}

export type EventLogsConfigTimeRangeOverride = {
  enabled?: boolean
  timeRange?: CommonCommon.TimeRange
}

export type EventLogsConfig = {
  columnsConfig?: CommonCommon.EventLogConfig
  timeRangeOverride?: EventLogsConfigTimeRangeOverride
  query?: string
  sourceName?: string
}

export type Chart = {
  type?: ChartChartType
  queries?: CommonCommon.Query[]
  formulas?: CommonCommon.Formula[]
  config?: ChartConfig
  note?: Note
  datasourceType?: ChartDataSourceType
  segmentationQueries?: CommonCommon.SegmentationQuery[]
  insightsQueries?: Insights_serviceInsights_service.QueryRequestQuery[]
  eventLogsConfig?: EventLogsConfig
  retentionQuery?: CommonCommon.RetentionQuery
  sqlQuery?: string
}

export type ChartConfigYAxisConfig = {
  min?: string
  max?: string
  scale?: boolean
  stacked?: string
}

export type ChartConfigBarGaugeConfig = {
  direction?: ChartConfigDirection
  calculation?: ChartConfigCalculation
  sort?: ChartConfigSort
}

export type ChartConfigSort = {
  sortBy?: ChartConfigSortBy
  orderDesc?: boolean
}

export type ChartConfigValueConfig = {
  valueFormatter?: ChartConfigValueFormatter
  showValueLabel?: boolean
  maxSignificantDigits?: number
  dateFormat?: string
  mappingRules?: ChartConfigMappingRule[]
  style?: ChartConfigValueConfigStyle
  maxFractionDigits?: number
  precision?: number
  currencySymbol?: string
}

export type ChartConfigMappingRule = {
  comparison?: string
  value?: number
  text?: string
  colorTheme?: ChartConfigColorTheme
}

export type ChartConfigTimeRangeOverride = {
  enabled?: boolean
  timeRange?: CommonCommon.TimeRange
  compareTime?: ChartConfigCompareTime
}

export type ChartConfigCompareTime = {
  ago?: CommonCommon.Duration
}

export type ChartConfigTableConfig = {
  calculation?: ChartConfigCalculation
  showColumns?: {[key: string]: boolean}
  sortColumns?: ChartConfigColumnSort[]
  columnOrders?: string[]
  columnWidths?: {[key: string]: number}
  showPlainData?: boolean
  calculations?: {[key: string]: ChartConfigCalculation}
  valueConfigs?: {[key: string]: ChartConfigValueConfig}
  rowLimit?: number
}

export type ChartConfigColumnSort = {
  column?: string
  orderDesc?: boolean
}

export type ChartConfigQueryValueConfig = {
  colorTheme?: ChartConfigColorTheme
  showBackgroundChart?: boolean
  calculation?: ChartConfigCalculation
  seriesCalculation?: ChartConfigCalculation
}

export type ChartConfigColorTheme = {
  textColor?: string
  backgroundColor?: string
  themeType?: string
}

export type ChartConfigPieConfig = {
  pieType?: ChartConfigPieConfigPieType
  showPercent?: boolean
  showValue?: boolean
  calculation?: ChartConfigCalculation
}

export type ChartConfigMarker = {
  type?: ChartConfigMarkerType
  value?: number
  color?: string
  label?: string
  valueX?: string
}

export type ChartConfigLineConfig = {
  style?: ChartConfigLineConfigStyle
}

export type ChartConfig = {
  yAxis?: ChartConfigYAxisConfig
  barGauge?: ChartConfigBarGaugeConfig
  valueConfig?: ChartConfigValueConfig
  timeRangeOverride?: ChartConfigTimeRangeOverride
  tableConfig?: ChartConfigTableConfig
  queryValueConfig?: ChartConfigQueryValueConfig
  pieConfig?: ChartConfigPieConfig
  markers?: ChartConfigMarker[]
  lineConfig?: ChartConfigLineConfig
}

export type GetDashboardRequest = {
  dashboardId?: string
  projectId?: string
  ownerName?: string
  slug?: string
}

export type GetDashboardResponse = {
  dashboards?: Dashboard[]
  permissions?: CommonCommon.Permission[]
}

export type GetExternalDashboardRequest = {
  dashboardId?: string
  projectId?: string
  ownerName?: string
  slug?: string
  url?: string
}

export type GetExternalDashboardResponse = {
  dashboard?: DashboardResult
}

export type ListExternalDashboardsRequestNameTagsFilter = {
  tags?: string[]
  name?: string
}


type BaseListExternalDashboardsRequest = {
  projectId?: string
  ownerName?: string
  slug?: string
  orderBy?: ListExternalDashboardsRequestOrderBy
  orderByTimeRange?: string
  limit?: number
  offset?: number
}

export type ListExternalDashboardsRequest = BaseListExternalDashboardsRequest
  & OneOf<{ ownerFilter: string; tagsFilter: ListExternalDashboardsRequestNameTagsFilter; starFilter: boolean }>

export type DashboardResult = {
  dashboard?: Dashboard
  starred?: boolean
  permissions?: CommonCommon.Permission[]
  starCount?: number
}

export type ListExternalDashboardsResponse = {
  dashboards?: DashboardResult[]
}


type BaseGetUserRequest = {
}

export type GetUserRequest = BaseGetUserRequest
  & OneOf<{ userId: string; subject: string; email: string }>


type BaseGetUserInfoRequest = {
}

export type GetUserInfoRequest = BaseGetUserInfoRequest
  & OneOf<{ userId: string; userName: string; email: string }>

export type SearchUsersInfoRequest = {
  query?: string
  limit?: number
  offset?: number
}

export type SearchUsersInfoResponse = {
  users?: CommonCommon.UserInfo[]
}

export type GetProjectByIdRequest = {
  projectId?: string
}

export type GetProjectResponse = {
  project?: CommonCommon.Project
  permissions?: CommonCommon.Permission[]
}

export type GetApiKeyRequest = {
}

export type DeleteApiKeyRequest = {
  apiKeyId?: string
}

export type GetApiKeyResponse = {
  apiKeys?: CommonCommon.ApiKey[]
}

export type GenerateApiKeyRequest = {
  name?: string
  scopes?: string[]
  source?: string
}

export type GenerateApiKeyResponse = {
  apiKey?: CommonCommon.ApiKey
  key?: string
  username?: string
}

export type ProjectOwnerAndSlug = {
  ownerName?: string
  slug?: string
}

export type CheckOwnerRequest = {
  ownerName?: string
}

export type CheckResponse = {
  isTaken?: boolean
  isValid?: boolean
}

export type GetProjectListRequest = {
  userId?: string
  organizationId?: string
}

export type GetProjectListResponse = {
  projects?: CommonCommon.Project[]
  sharedProjects?: CommonCommon.Project[]
  orgProjects?: CommonCommon.Project[]
}

export type ProjectMemberRequest = {
  projectId?: string
  usernameOrEmail?: string
}

export type ProjectMemberResponse = {
  members?: CommonCommon.ProjectProjectMember[]
}

export type ExportDashboardRequest = {
  dashboardId?: string
}

export type ExportDashboardResponse = {
  dashboardJson?: GoogleProtobufStruct.Struct
}

export type ImportDashboardRequest = {
  dashboardId?: string
  dashboardJson?: GoogleProtobufStruct.Struct
  overrideLayouts?: boolean
}

export type ImportDashboardResponse = {
  dashboard?: Dashboard
}

export type Snapshot = {
  id?: string
  name?: string
  projectId?: string
  projectSlug?: string
  ownerId?: string
  ownerName?: string
  chart?: Chart
  data?: GoogleProtobufStruct.Struct
  startTime?: GoogleProtobufTimestamp.Timestamp
  endTime?: GoogleProtobufTimestamp.Timestamp
  createdAt?: GoogleProtobufTimestamp.Timestamp
}

export type SnapshotRequest = {
  snapshotId?: string
  snapshot?: Snapshot
}

export type SnapshotResponse = {
  snapshot?: Snapshot
}

export type GetOrganizationRequest = {
  orgIdOrName?: string
}

export type GetOrganizationResponse = {
  organizations?: CommonCommon.Organization[]
}

export type OrganizationMemberRequest = {
  orgIdOrName?: string
  usernameOrEmail?: string
  role?: CommonCommon.OrganizationRole
}

export type DashboardSharing = {
  id?: string
  dashboardId?: string
  isPublic?: boolean
  viewers?: string[]
}

export type InvitationRequest = {
  id?: string
  toEmail?: string
  toOrgName?: string
  toOrgRole?: CommonCommon.OrganizationRole
  toProject?: string
}

export type Invitation = {
  id?: string
  toEmail?: string
  from?: CommonCommon.UserInfo
  to?: CommonCommon.UserInfo
  organization?: CommonCommon.Organization
  orgRole?: CommonCommon.OrganizationRole
  createdAt?: GoogleProtobufTimestamp.Timestamp
  project?: CommonCommon.Project
}

export type SignUpRequest = {
  user?: CommonCommon.User
  invitation?: string
}

export type GetDashboardSharingRequest = {
  sharingId?: string
}

export type GetDashboardSharingResponse = {
  dashboard?: Dashboard
  project?: CommonCommon.Project
}

export type ProjectViewResponse = {
  projectId?: string
  views?: CommonCommon.ProjectView[]
}

export type ImportProjectRequest = {
  ownerName?: string
  slug?: string
  name?: string
  importProject?: ProjectOwnerAndSlug
  importProjects?: ProjectOwnerAndSlug[]
}

export type UnImportProjectRequest = {
  ownerName?: string
  slug?: string
  unimportOwner?: string
  unimportSlug?: string
}

export type ImportProjectResponse = {
  imports?: CommonCommon.ImportedProject[]
}

export type SearchProjectRequest = {
  q?: string
}

export type SearchProjectResponse = {
  projects?: CommonCommon.ProjectInfo[]
}

export type ProjectsResponse = {
  projects?: CommonCommon.Project[]
}

export type ProjectsInfoResponse = {
  projects?: CommonCommon.ProjectInfo[]
}

export type TagsResponse = {
  tags?: string[]
  counts?: number[]
}

export type ProjectVariableKey = {
  projectId?: string
  key?: string
}

export type NotificationResponse = {
  notifications?: CommonCommon.Notification[]
}

export type NotificationReadRequest = {
  ids?: string[]
}

export type NotificationRequest = {
  limit?: number
  offset?: number
}

export type UnreadNotificationResponse = {
  count?: number
}


type BaseCloneProjectRequest = {
  fromProjectId?: string
}

export type CloneProjectRequest = BaseCloneProjectRequest
  & OneOf<{ projectId: string; project: CommonCommon.Project }>

export class WebService {
  static CheckOwner(req: CheckOwnerRequest, initReq?: fm.InitReq): Promise<CheckResponse> {
    return fm.fetchReq<CheckOwnerRequest, CheckResponse>(`/api/v1/users/${req["ownerName"]}/availability?${fm.renderURLSearchParams(req, ["ownerName"])}`, {...initReq, method: "GET"})
  }
  static CreateUser(req: SignUpRequest, initReq?: fm.InitReq): Promise<CommonCommon.User> {
    return fm.fetchReq<SignUpRequest, CommonCommon.User>(`/api/v1/users`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static UpdateUser(req: CommonCommon.User, initReq?: fm.InitReq): Promise<CommonCommon.User> {
    return fm.fetchReq<CommonCommon.User, CommonCommon.User>(`/api/v1/users`, {...initReq, method: "PUT", body: JSON.stringify(req, fm.replacer)})
  }
  static GetUser(req: GetUserRequest, initReq?: fm.InitReq): Promise<CommonCommon.User> {
    return fm.fetchReq<GetUserRequest, CommonCommon.User>(`/api/v1/users?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static GetUserInfo(req: GetUserInfoRequest, initReq?: fm.InitReq): Promise<CommonCommon.UserInfo> {
    return fm.fetchReq<GetUserInfoRequest, CommonCommon.UserInfo>(`/api/v1/users/info?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static SaveOrganization(req: CommonCommon.Organization, initReq?: fm.InitReq): Promise<CommonCommon.Organization> {
    return fm.fetchReq<CommonCommon.Organization, CommonCommon.Organization>(`/api/v1/organizations`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static GetOrganization(req: GetOrganizationRequest, initReq?: fm.InitReq): Promise<GetOrganizationResponse> {
    return fm.fetchReq<GetOrganizationRequest, GetOrganizationResponse>(`/api/v1/organizations?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static DeleteOrganization(req: GetOrganizationRequest, initReq?: fm.InitReq): Promise<CommonCommon.Organization> {
    return fm.fetchReq<GetOrganizationRequest, CommonCommon.Organization>(`/api/v1/organizations/${req["orgIdOrName"]}`, {...initReq, method: "DELETE"})
  }
  static AddOrganizationMember(req: OrganizationMemberRequest, initReq?: fm.InitReq): Promise<CommonCommon.Organization> {
    return fm.fetchReq<OrganizationMemberRequest, CommonCommon.Organization>(`/api/v1/organizations/${req["orgIdOrName"]}/members`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static RemoveOrganizationMember(req: OrganizationMemberRequest, initReq?: fm.InitReq): Promise<CommonCommon.Organization> {
    return fm.fetchReq<OrganizationMemberRequest, CommonCommon.Organization>(`/api/v1/organizations/${req["orgIdOrName"]}/members/${req["usernameOrEmail"]}`, {...initReq, method: "DELETE"})
  }
  static UpdateOrganizationMember(req: OrganizationMemberRequest, initReq?: fm.InitReq): Promise<CommonCommon.Organization> {
    return fm.fetchReq<OrganizationMemberRequest, CommonCommon.Organization>(`/api/v1/organizations/${req["orgIdOrName"]}/members/${req["usernameOrEmail"]}`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static SaveDashboard(req: Dashboard, initReq?: fm.InitReq): Promise<Dashboard> {
    return fm.fetchReq<Dashboard, Dashboard>(`/api/v1/dashboards`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static ListTags(req: GoogleProtobufEmpty.Empty, initReq?: fm.InitReq): Promise<TagsResponse> {
    return fm.fetchReq<GoogleProtobufEmpty.Empty, TagsResponse>(`/api/v1/dashboards_tags?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static StarDashboard(req: GetDashboardRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<GetDashboardRequest, GoogleProtobufEmpty.Empty>(`/api/v1/dashboards/${req["dashboardId"]}/star`, {...initReq, method: "POST"})
  }
  static UnStarDashboard(req: GetDashboardRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<GetDashboardRequest, GoogleProtobufEmpty.Empty>(`/api/v1/dashboards/${req["dashboardId"]}/star`, {...initReq, method: "DELETE"})
  }
  static ListDashboards(req: GetDashboardRequest, initReq?: fm.InitReq): Promise<GetDashboardResponse> {
    return fm.fetchReq<GetDashboardRequest, GetDashboardResponse>(`/api/v1/dashboards?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static ListExternalDashboards(req: ListExternalDashboardsRequest, initReq?: fm.InitReq): Promise<ListExternalDashboardsResponse> {
    return fm.fetchReq<ListExternalDashboardsRequest, ListExternalDashboardsResponse>(`/api/v1/external_dashboards`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static GetExternalDashboard(req: GetExternalDashboardRequest, initReq?: fm.InitReq): Promise<GetExternalDashboardResponse> {
    return fm.fetchReq<GetExternalDashboardRequest, GetExternalDashboardResponse>(`/api/v1/external_dashboard?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static GetDashboard(req: GetDashboardRequest, initReq?: fm.InitReq): Promise<GetDashboardResponse> {
    return fm.fetchReq<GetDashboardRequest, GetDashboardResponse>(`/api/v1/dashboards/${req["dashboardId"]}?${fm.renderURLSearchParams(req, ["dashboardId"])}`, {...initReq, method: "GET"})
  }
  static DeleteDashboard(req: GetDashboardRequest, initReq?: fm.InitReq): Promise<Dashboard> {
    return fm.fetchReq<GetDashboardRequest, Dashboard>(`/api/v1/dashboards/${req["dashboardId"]}`, {...initReq, method: "DELETE"})
  }
  static ExportDashboard(req: ExportDashboardRequest, initReq?: fm.InitReq): Promise<ExportDashboardResponse> {
    return fm.fetchReq<ExportDashboardRequest, ExportDashboardResponse>(`/api/v1/dashboards/${req["dashboardId"]}/json?${fm.renderURLSearchParams(req, ["dashboardId"])}`, {...initReq, method: "GET"})
  }
  static ImportDashboard(req: ImportDashboardRequest, initReq?: fm.InitReq): Promise<ImportDashboardResponse> {
    return fm.fetchReq<ImportDashboardRequest, ImportDashboardResponse>(`/api/v1/dashboards/json`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static GetProject(req: ProjectOwnerAndSlug, initReq?: fm.InitReq): Promise<GetProjectResponse> {
    return fm.fetchReq<ProjectOwnerAndSlug, GetProjectResponse>(`/api/v1/project/${req["ownerName"]}/${req["slug"]}?${fm.renderURLSearchParams(req, ["ownerName", "slug"])}`, {...initReq, method: "GET"})
  }
  static GetProjectList(req: GetProjectListRequest, initReq?: fm.InitReq): Promise<GetProjectListResponse> {
    return fm.fetchReq<GetProjectListRequest, GetProjectListResponse>(`/api/v1/projects?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static GetPopulateProjectList(req: GetProjectListRequest, initReq?: fm.InitReq): Promise<GetProjectListResponse> {
    return fm.fetchReq<GetProjectListRequest, GetProjectListResponse>(`/api/v1/populate-projects?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static SaveProject(req: CommonCommon.Project, initReq?: fm.InitReq): Promise<CommonCommon.Project> {
    return fm.fetchReq<CommonCommon.Project, CommonCommon.Project>(`/api/v1/projects`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static DeleteProject(req: GetProjectByIdRequest, initReq?: fm.InitReq): Promise<CommonCommon.Project> {
    return fm.fetchReq<GetProjectByIdRequest, CommonCommon.Project>(`/api/v1/projects/${req["projectId"]}`, {...initReq, method: "DELETE"})
  }
  static CloneProject(req: CloneProjectRequest, initReq?: fm.InitReq): Promise<CommonCommon.Project> {
    return fm.fetchReq<CloneProjectRequest, CommonCommon.Project>(`/api/v1/projects/clone`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static AddProjectMember(req: ProjectMemberRequest, initReq?: fm.InitReq): Promise<ProjectMemberResponse> {
    return fm.fetchReq<ProjectMemberRequest, ProjectMemberResponse>(`/api/v1/projects/${req["projectId"]}/members`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static RemoveProjectMember(req: ProjectMemberRequest, initReq?: fm.InitReq): Promise<ProjectMemberResponse> {
    return fm.fetchReq<ProjectMemberRequest, ProjectMemberResponse>(`/api/v1/projects/${req["projectId"]}/members/${req["usernameOrEmail"]}`, {...initReq, method: "DELETE"})
  }
  static CheckProjectId(req: ProjectOwnerAndSlug, initReq?: fm.InitReq): Promise<CheckResponse> {
    return fm.fetchReq<ProjectOwnerAndSlug, CheckResponse>(`/api/v1/projects/${req["ownerName"]}/${req["slug"]}/availability?${fm.renderURLSearchParams(req, ["ownerName", "slug"])}`, {...initReq, method: "GET"})
  }
  static GetApiKey(req: GetApiKeyRequest, initReq?: fm.InitReq): Promise<GetApiKeyResponse> {
    return fm.fetchReq<GetApiKeyRequest, GetApiKeyResponse>(`/api/v1/keys?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static GenerateApiKey(req: GenerateApiKeyRequest, initReq?: fm.InitReq): Promise<GenerateApiKeyResponse> {
    return fm.fetchReq<GenerateApiKeyRequest, GenerateApiKeyResponse>(`/api/v1/keys`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static DeleteApiKey(req: DeleteApiKeyRequest, initReq?: fm.InitReq): Promise<CommonCommon.ApiKey> {
    return fm.fetchReq<DeleteApiKeyRequest, CommonCommon.ApiKey>(`/api/v1/keys/${req["apiKeyId"]}`, {...initReq, method: "DELETE"})
  }
  static SaveSnapshot(req: SnapshotRequest, initReq?: fm.InitReq): Promise<SnapshotResponse> {
    return fm.fetchReq<SnapshotRequest, SnapshotResponse>(`/api/v1/snapshots/${req["snapshotId"]}`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static DeleteSnapshot(req: SnapshotRequest, initReq?: fm.InitReq): Promise<SnapshotResponse> {
    return fm.fetchReq<SnapshotRequest, SnapshotResponse>(`/api/v1/snapshots/${req["snapshotId"]}`, {...initReq, method: "DELETE"})
  }
  static GetSnapshot(req: SnapshotRequest, initReq?: fm.InitReq): Promise<SnapshotResponse> {
    return fm.fetchReq<SnapshotRequest, SnapshotResponse>(`/api/v1/snapshots/${req["snapshotId"]}?${fm.renderURLSearchParams(req, ["snapshotId"])}`, {...initReq, method: "GET"})
  }
  static SaveDashboardSharing(req: DashboardSharing, initReq?: fm.InitReq): Promise<DashboardSharing> {
    return fm.fetchReq<DashboardSharing, DashboardSharing>(`/api/v1/dashboards/${req["dashboardId"]}/sharing`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static RemoveDashboardSharing(req: DashboardSharing, initReq?: fm.InitReq): Promise<DashboardSharing> {
    return fm.fetchReq<DashboardSharing, DashboardSharing>(`/api/v1/dashboards/${req["dashboardId"]}/sharing`, {...initReq, method: "DELETE"})
  }
  static SaveInvitation(req: InvitationRequest, initReq?: fm.InitReq): Promise<Invitation> {
    return fm.fetchReq<InvitationRequest, Invitation>(`/api/v1/invitations`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static GetDashboardSharing(req: GetDashboardSharingRequest, initReq?: fm.InitReq): Promise<GetDashboardSharingResponse> {
    return fm.fetchReq<GetDashboardSharingRequest, GetDashboardSharingResponse>(`/api/v1/sharing_dashboard/${req["sharingId"]}?${fm.renderURLSearchParams(req, ["sharingId"])}`, {...initReq, method: "GET"})
  }
  static AddProjectView(req: CommonCommon.ProjectView, initReq?: fm.InitReq): Promise<ProjectViewResponse> {
    return fm.fetchReq<CommonCommon.ProjectView, ProjectViewResponse>(`/api/v1/project_view/${req["projectId"]}`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static RemoveProjectView(req: CommonCommon.ProjectView, initReq?: fm.InitReq): Promise<ProjectViewResponse> {
    return fm.fetchReq<CommonCommon.ProjectView, ProjectViewResponse>(`/api/v1/project_view/${req["projectId"]}/${req["id"]}`, {...initReq, method: "DELETE"})
  }
  static UpdateProjectView(req: CommonCommon.ProjectView, initReq?: fm.InitReq): Promise<ProjectViewResponse> {
    return fm.fetchReq<CommonCommon.ProjectView, ProjectViewResponse>(`/api/v1/project_view/${req["projectId"]}/${req["id"]}`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static SearchProject(req: SearchProjectRequest, initReq?: fm.InitReq): Promise<SearchProjectResponse> {
    return fm.fetchReq<SearchProjectRequest, SearchProjectResponse>(`/api/v1/projects/search?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static ImportProject(req: ImportProjectRequest, initReq?: fm.InitReq): Promise<ImportProjectResponse> {
    return fm.fetchReq<ImportProjectRequest, ImportProjectResponse>(`/api/v1/project/${req["ownerName"]}/${req["slug"]}/importprojects`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static UnImportProject(req: UnImportProjectRequest, initReq?: fm.InitReq): Promise<ImportProjectResponse> {
    return fm.fetchReq<UnImportProjectRequest, ImportProjectResponse>(`/api/v1/project/${req["ownerName"]}/${req["slug"]}/unimportprojects/${req["unimportOwner"]}/${req["unimportSlug"]}`, {...initReq, method: "DELETE"})
  }
  static GetImportedProject(req: ProjectOwnerAndSlug, initReq?: fm.InitReq): Promise<ImportProjectResponse> {
    return fm.fetchReq<ProjectOwnerAndSlug, ImportProjectResponse>(`/api/v1/project/${req["ownerName"]}/${req["slug"]}/importprojects?${fm.renderURLSearchParams(req, ["ownerName", "slug"])}`, {...initReq, method: "GET"})
  }
  static GetStarredProjects(req: GoogleProtobufEmpty.Empty, initReq?: fm.InitReq): Promise<ProjectsResponse> {
    return fm.fetchReq<GoogleProtobufEmpty.Empty, ProjectsResponse>(`/api/v1/starred_projects?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static StarProject(req: GetProjectByIdRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<GetProjectByIdRequest, GoogleProtobufEmpty.Empty>(`/api/v1/starred_projects/${req["projectId"]}`, {...initReq, method: "POST"})
  }
  static UnstarProject(req: GetProjectByIdRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<GetProjectByIdRequest, GoogleProtobufEmpty.Empty>(`/api/v1/starred_projects/${req["projectId"]}`, {...initReq, method: "DELETE"})
  }
  static GetViewedProjects(req: GoogleProtobufEmpty.Empty, initReq?: fm.InitReq): Promise<ProjectsInfoResponse> {
    return fm.fetchReq<GoogleProtobufEmpty.Empty, ProjectsInfoResponse>(`/api/v1/viewed_projects?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static SearchUsersInfo(req: SearchUsersInfoRequest, initReq?: fm.InitReq): Promise<SearchUsersInfoResponse> {
    return fm.fetchReq<SearchUsersInfoRequest, SearchUsersInfoResponse>(`/api/v1/users/search?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static GetProjectVariables(req: GetProjectByIdRequest, initReq?: fm.InitReq): Promise<CommonCommon.ProjectVariables> {
    return fm.fetchReq<GetProjectByIdRequest, CommonCommon.ProjectVariables>(`/api/v1/projects/${req["projectId"]}/variables?${fm.renderURLSearchParams(req, ["projectId"])}`, {...initReq, method: "GET"})
  }
  static SaveProjectVariables(req: CommonCommon.ProjectVariables, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<CommonCommon.ProjectVariables, GoogleProtobufEmpty.Empty>(`/api/v1/projects/${req["projectId"]}/variables`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static DeleteProjectVariables(req: ProjectVariableKey, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<ProjectVariableKey, GoogleProtobufEmpty.Empty>(`/api/v1/projects/${req["projectId"]}/variables/${req["key"]}`, {...initReq, method: "DELETE"})
  }
  static GetNotifications(req: NotificationRequest, initReq?: fm.InitReq): Promise<NotificationResponse> {
    return fm.fetchReq<NotificationRequest, NotificationResponse>(`/api/v1/notifications?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static ReadNotification(req: NotificationReadRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<NotificationReadRequest, GoogleProtobufEmpty.Empty>(`/api/v1/notifications/read`, {...initReq, method: "PUT", body: JSON.stringify(req, fm.replacer)})
  }
  static UnreadNotification(req: GoogleProtobufEmpty.Empty, initReq?: fm.InitReq): Promise<UnreadNotificationResponse> {
    return fm.fetchReq<GoogleProtobufEmpty.Empty, UnreadNotificationResponse>(`/api/v1/notifications/unread?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
}