import { useEffect, useState } from 'react'
import { BaseErrorPageLayout, BaseErrorContent } from './BaseErrorPage'

export default function NotFound() {
  const [path, setPath] = useState('')
  useEffect(() => {
    setPath(document.location.pathname)
  }, [])
  const [owner, slug, page, id] = path.slice(1).split('/')
  return (
    <BaseErrorPageLayout code={404}>
      <BaseErrorContent
        title={page === 'dashboards' ? `Dashboard ${id} not found` : 'Page not found'}
        description="Please check the URL in the address bar and try again."
        buttons={[
          {
            text: `Go to ${owner}/${slug}`,
            type: 'primary',
            href: `/${owner}/${slug}`,
          },
          {
            text: 'Go back home',
            type: 'secondary',
            href: '/',
          },
          {
            text: 'Sentio Documentation',
            type: 'secondary',
            href: 'https://docs.sentio.xyz',
          },
        ]}
      />
    </BaseErrorPageLayout>
  )
}
