import { Bars3Icon as MenuIcon } from '@heroicons/react/24/outline'
import { createContext, ReactElement } from 'react'

export const MobileMenuContext = createContext<undefined | ReactElement>(undefined)

interface Props {
  setSidebarOpen: (open: boolean) => void
}

export const MobileMenu = ({ setSidebarOpen }: Props) => {
  return (
    <div className="dark:bg-sentio-gray-100 sticky top-0 z-10 bg-white sm:pl-3 sm:pt-3 md:hidden">
      <button
        type="button"
        className="focus:ring-primary-500 hover:text-text-foreground -ml-0.5 -mt-0.5 inline-flex h-8 w-8 items-center justify-center rounded-md text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <MenuIcon className="h-6 w-6" aria-hidden="true" />
      </button>
    </div>
  )
}
